import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import moment from "moment";

const StatusListTable = (props) => {
    const { arrayData, ...other } = props;
    const [headers, setHeaders] = useState([
        {
            id: "date",
            name: "Date"
        },
        {
            id: "day",
            name: "Day"
        },
        {
            id: "in",
            name: "In"
        },
        {
            id: "out",
            name: "Out"
        },
        {
            id: "hour",
            name: "Hours"
        },
        {
            id: "dayTotal",
            name: "Day Total"
        },
        {
            id: "weekTotal",
            name: "Week Total"
        },
        {
            id: "notes",
            name: "Notes"
        },
    ])

    useEffect(() => {
    }, [arrayData]);

    const onClick = (id) => {
    
    }

    return (
        <Box sx={{ overflowX: 'auto', minWidth: '100%', maxWidth: '100%' }}>
            <Table size="medium">
                <TableHead>
                    <TableRow style={{ height: '40px', padding: "0", margin: "0" }}>
                        {headers.map((header) => (
                            <TableCell key={header.id} style={{ paddingBlock: '0px' }}>
                                <Typography color="#262B40" variant="primary" fontFamily={"Roboto"} fontSize={'13px'} fontWeight='bold'>
                                    {header.name}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {arrayData.map((data, index) => {
                        return (<React.Fragment key={data._id}>
                            <TableRow hover key={data._id} style={{ height: '38px', }}>
                                <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)} colSpan={8}>
                                    <Typography color="#909090" variant="primary" fontSize={'12px'} fontWeight='bold' paddingRight={"20px"}>
                                        {data.week}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {data.arrayData.map((employeeActivity, index) => {
                                return (
                                    <TableRow hover key={employeeActivity._id} style={{ height: '38px', }}>
                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal' >
                                                {moment.parseZone(employeeActivity.localStartTime + "").format("MM/DD/YYYY")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {moment.parseZone(employeeActivity.localStartTime + "").format("ddd")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {moment.parseZone(employeeActivity.localStartTime + "").format("hh:mm a")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {moment.parseZone(employeeActivity.localEndTime + "").format("hh:mm a")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.hour}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.day}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.week}
                                            </Typography>
                                        </TableCell>

                                        <TableCell  style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                        <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.notes}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </React.Fragment>)
                    })}
                </TableBody>

            </Table>
        </Box>

    );

}


StatusListTable.propTypes = {
    arrayData: PropTypes.array.isRequired
};

export default StatusListTable;