import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Popup } from 'components/Popup/index.js';
import api from 'lib/axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';

export const ManageTabletDeviceSiteChange = ({ onCancel, selectedSiteId, tabletDeviceID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [arraySite, setArraySite] = useState([]);

    useEffect(() => {
        getSite()
    }, []);

    function getSite() {
        setIsLoading(true)
        api.get('/apis/companysites/byCompany/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySite(resData)
                var selectedSite = resData.find((site) => site._id == selectedSiteId)
                setSelectedStatus(selectedSite)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function siteChange() {
        setIsLoading(true)
        var data = {
            deviceID: tabletDeviceID,
            siteID: selectedStatus._id,
        }
        api.put('/apis/Devices/changeSite/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData);
                setIsLoading(false)
                onCancel(true)
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
                maxHeight: '500px',
            }}
        >
            <Stack height={"100%"} p={2}>
                <Stack p={2}>
                    <DefaultSelect
                        // onBlur={handleBlur}
                        onChange={(event, newValue) => {
                            console.log("newValue", newValue);
                            setSelectedStatus(newValue)
                        }}
                        value={selectedStatus}
                        options={arraySite}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                        name="selectedSite"
                        label={"Site"}
                        placeholder="Select Site" >

                    </DefaultSelect>
                    <Stack mt={3} justifyContent={"center"} width={"100%"}>
                        <Typography fontSize={"15px"} textAlign={"center"} color={"#FF0000"}> Warning: Changing the site will remove all employees currently assigned to this tablet device.</Typography>
                    </Stack>
                </Stack>
                <Box flexGrow={1} />
                <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                    <Box flexGrow={1} />
                    <DefaultButton onClick={() => { onCancel(false) }}>Cancel</DefaultButton>
                    <DefaultButton color={'success'} onClick={siteChange}>
                        Save
                    </DefaultButton>
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    )
}