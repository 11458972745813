import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { tabs } from 'modules/Settings/data/tabs.js';
import { SettingsUsersTab } from './tabs/Users/SettingsUsersTab.jsx';
import { SettingsSiteDetailsTab } from './tabs/Site/SettingsSiteDetailsTab.jsx';
import { SettingsShiftsTab } from './tabs/Shifts/SettingsShiftsTab.jsx';
import { SettingDivisionTab } from './tabs/Division/SettingDivisionTab.jsx';
import { SettingsCompanyDetailsTab } from './tabs/Company/SettingsCompanyDetailsTab.jsx';
import { SettingsTabletDevicesTab } from './tabs/TabletDevice/SettingsTabletDevicesTab.jsx';

export const Settings = () => {
    const [activeTab, setActiveTab] = useState(tabs[localStorage.getItem("role") === "Standard" ? 1 : 0].value);
    const [isOpenedUserDetails, setIsOpenedUserDetails] = useState(null);
    const [isOpenedSiteDetails, setIsOpenedSiteDetails] = useState(null);
    const [isOpenedShiftDetails, setIsOpenedShiftDetails] = useState(null);
    const [tabletDeviceID, setIsOpenedTabletDeviceDetails] = useState(null);
    const [divisionID, setIsOpenedDivisionDetails] = useState(null);
    const [isOpenedCompanyDetails, setIsOpenedCompanyDetails] = useState(null);


    useEffect(() => {
        setIsOpenedUserDetails(null);
    }, [activeTab]);

    return (
        <Stack p={2.5} width={'100%'}>
            <Box bgcolor={'#F6F6F7'} pt={0} borderRadius={'10px 10px 0 0'} border={'1px solid #DBDDE2'} borderBottom={0}>
                <Stack mb={3} m={2} direction={'row'} justifyContent={'space-between'} spacing={3} alignItems={'center'} sx={{
                    pointerEvents: (isOpenedUserDetails || isOpenedSiteDetails || isOpenedShiftDetails || tabletDeviceID || divisionID || isOpenedCompanyDetails) && 'none',

                }}>
                    <Stack direction={'row'} borderBottom={'1px solid #DBDDE2'}>
                        {tabs.map(({ id, label, value }) => {
                            if (localStorage.getItem("role") === "Standard" && id == 1) {
                                return <span key={123} />
                            }
                            return (
                                <ButtonBase
                                    type={'button'}
                                    key={id}
                                    sx={{
                                        px: 3,
                                        py: 2,
                                        position: 'relative',
                                    }}
                                    onClick={() => setActiveTab(value)}
                                >
                                    <Typography>{label}</Typography>
                                    {activeTab === value && (
                                        <Box
                                            width={'100%'}
                                            position={'absolute'}
                                            left={0}
                                            bottom={0}
                                            height={3}
                                            bgcolor={'primary.main'} />
                                    )}
                                </ButtonBase>
                            );
                        })}
                    </Stack>
                    {localStorage.getItem("role") !== "2" && <>
                        {activeTab === 'companydetail' && (
                            <ButtonBase
                                type={'button'}
                                bgcolor={'blue.light'}
                                onClick={() => setIsOpenedCompanyDetails(localStorage.getItem("siteID"))}
                                sx={{
                                    py: 1.5,
                                    px: 2,
                                    position: 'relative',
                                    boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                    borderRadius: '5px',
                                }}
                            >
                                Edit Company
                            </ButtonBase>
                        )}
                    </>}

                    {activeTab === 'users' && (
                        <ButtonBase
                            type={'button'}
                            bgcolor={'blue.light'}
                            onClick={() => setIsOpenedUserDetails("0")}
                            sx={{
                                py: 1.5,
                                px: 2,
                                position: 'relative',
                                boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                borderRadius: '5px',
                            }}
                        >
                            + New User
                        </ButtonBase>
                    )}
                    {activeTab === 'sitedetails' && (
                        <Stack direction={'row'} spacing={1} mt={'auto'} >
                            {localStorage.getItem("role") !== "2" && <>
                                <ButtonBase
                                    type={'button'}
                                    bgcolor={'blue.light'}
                                    onClick={() => setIsOpenedSiteDetails("0")}
                                    sx={{
                                        py: 1.5,
                                        px: 2,
                                        position: 'relative',
                                        boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                        borderRadius: '5px',
                                    }}
                                >
                                    + New Site
                                </ButtonBase>
                            </>}
                            <ButtonBase
                                type={'button'}
                                bgcolor={'blue.light'}
                                onClick={() => setIsOpenedSiteDetails(localStorage.getItem("siteID"))}
                                sx={{
                                    py: 1.5,
                                    px: 2,
                                    position: 'relative',
                                    boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                    borderRadius: '5px',
                                }}
                            >
                                Edit Site
                            </ButtonBase>
                        </Stack>
                    )}
                    {activeTab === 'shifts' && (
                        <ButtonBase
                            type={'button'}
                            bgcolor={'blue.light'}
                            onClick={() => setIsOpenedShiftDetails("0")}
                            sx={{
                                py: 1.5,
                                px: 2,
                                position: 'relative',
                                boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                borderRadius: '5px',
                            }}
                        >
                            + New Shift
                        </ButtonBase>
                    )}
                    {localStorage.getItem("role") !== "2" && <>
                        {activeTab === 'division' && (
                            <ButtonBase
                                type={'button'}
                                bgcolor={'blue.light'}
                                onClick={() => setIsOpenedDivisionDetails("0")}
                                sx={{
                                    py: 1.5,
                                    px: 2,
                                    position: 'relative',
                                    boxShadow: '0px 3px 6px rgba(0 0 0 / 16%)',
                                    borderRadius: '5px',
                                }}
                            >
                                + New Division
                            </ButtonBase>
                        )}
                    </>}
                </Stack>
            </Box>
            {activeTab === 'companydetail' && <SettingsCompanyDetailsTab isOpenedCompanyDetails={isOpenedCompanyDetails} setIsOpenedCompanyDetails={setIsOpenedCompanyDetails} />}
            {activeTab === 'users' && <SettingsUsersTab isOpenedUserDetails={isOpenedUserDetails} setIsOpenedUserDetails={setIsOpenedUserDetails} />}
            {activeTab === 'sitedetails' && <SettingsSiteDetailsTab isOpenedSiteDetails={isOpenedSiteDetails} setIsOpenedSiteDetails={setIsOpenedSiteDetails} />}
            {activeTab === 'shifts' && <SettingsShiftsTab isOpenedShiftDetails={isOpenedShiftDetails} setIsOpenedShiftDetails={setIsOpenedShiftDetails} />}
            {activeTab === 'tabletdevices' && <SettingsTabletDevicesTab tabletDeviceID={tabletDeviceID} setIsOpenedTabletDeviceDetails={setIsOpenedTabletDeviceDetails} />}
            {activeTab === 'division' && <SettingDivisionTab divisionID={divisionID} setIsOpenedDivisionDetails={setIsOpenedDivisionDetails} />}
        </Stack>
    );
};
