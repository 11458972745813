// Importing necessary components and libraries
import { Button, ButtonBase, Typography } from '@mui/material';
import { Stack, width } from '@mui/system';
import api from 'lib/axios.js';
import { enqueueSnackbar } from 'notistack';

export const arrayType = [
    "Monnit", "Minew"
]

export const arrayStatus = [
    {
        id: "1",
        value: 'Active'
    },
    {
        id: "0",
        value: 'Inactive'
    },
];

export const columnsTabletDevice = (onSiteChange) => [
    {
        field: 'name',
        headerName: 'name',
        width: 180,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'udid',
        headerName: 'Device ID',
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'model',
        headerName: 'Model',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        minWidth: 150,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <Stack width={"100%"} height={'100%'} justifyContent={"center"} >
                    <ButtonBase
                        type={'button'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSiteChange(params.row.siteID, params.row._id)
                        }}
                        sx={{
                            minWidth: "100%",
                            textTransform: 'none',
                            borderRadius: '5px',
                            height: '40px',
                            border: '0px solid black',
                            backgroundColor: '#A9A9A96F',
                            fontSize: "15px",
                            fontWeight: "500",
                            color: '#262B40',
                            '&:hover': {
                                bgcolor: '#A9A9A96F',
                            },
                        }}
                    >
                        Transfer Device
                    </ButtonBase>
                </Stack>
            )
        }
    },
];

// Function to fetch site gateways data from API
export const getGatewayApi = async () => {
    try {
        const apiResponse = await api.post('/apis/gateways/bySite', { siteID: localStorage.getItem("siteID") });
        if (apiResponse.data.success) {
            // Map the response data to required format
            return apiResponse.data.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to manage gateways data in API (create/update/delete)
export const manageGatewayApi = async (gatewayID, jsonData) => {
    try {
        let url = `/apis/gateways${gatewayID ? `/${gatewayID}` : ''}`
        console.log("mng:gtw:url", gatewayID)
        const apiResponse = await api(url, {
            data: jsonData,
            method: jsonData ? gatewayID ? "PUT" : "POST" : "DELETE"
        });

        if (apiResponse.data && apiResponse.data.success == 1) {
            enqueueSnackbar('Gateway ' + (jsonData ? gatewayID ? "updated" : "added" : "delete"), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            return apiResponse.data;
        } else {
            enqueueSnackbar(apiResponse.data.message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to fetch gateways data by ID from API
export const getGatewayDataApi = async (gatewayID) => {
    try {
        const apiResponse = await api.post(`/apis/gateways/byID`, { id: gatewayID });
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};