import { Stack, Checkbox, Typography } from '@mui/material';

export const columns = (arraySelected, arrayEmployee, onCheck, handleHeaderClick) => [
    {
        field: 'selected',
        headerName: 'Select All',
        width: 120,
        renderHeader: (params) => (
            <Typography onClick={handleHeaderClick} textAlign={"center"}>
                {arraySelected.length == arrayEmployee.length ? "Unselect All" : "Select All"}
            </Typography>
        ),
        renderCell: (params) => (
            <Stack alignItems={'center'} width={'100%'} pr={5} >
                <Checkbox color="primary"
                    onChange={(value) => {
                        onCheck(params.row)
                    }}
                    checked={arraySelected.includes(params.row._id)}
                />
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: 'employeeName',
        headerName: 'Employee Name',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.firstName + " " + params.row.middleName + " " + params.row.lastName,
    },
    // {
    //     field: 'shiftName',
    //     headerName: 'Shift',
    //     flex: 1,
    //     valueGetter: (params) => params.row.shiftName ? params.row.shiftName : "-",
    // },
];
