import { Backdrop, Box, CircularProgress, Dialog, Stack } from "@mui/material"
import { DefaultDataGrid } from "ui/DefaultDataGrid";
import { columns } from "./data/employee";
import api from "lib/axios";
import { useEffect, useState } from "react";
import { SearchInput } from "components/SearchInput";
import { DefaultButton } from "ui/DefaultButton";

export const SelectEmployeeAlert = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayEmployee, setArrayEmployee] = useState([]);
    const [arraySelectedEmployee, setArraySelectedEmployee] = useState(props.selectedArrayEmployee || []);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.get('/apis/Employees/getAllBySite/' + localStorage.getItem("siteID")).then((res) => {
            if (res.data.success) {
                // var resData = res.data.data
                var resData = res.data.data.map((employee) => ({
                    ...employee,
                    name: `${employee.firstName || ""} ${employee.middleName || ""} ${employee.lastName || ""}`.trim()
                }));
                setArrayEmployee(resData)
                var filterArray = applyFilters(resData, query)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function onCheck(data) {
        if (arraySelectedEmployee.includes(data._id)) {
            setArraySelectedEmployee(arraySelectedEmployee.filter(id => id !== data._id));
        } else {
            setArraySelectedEmployee((prevState) => [...prevState, data._id]);
        }
    }


    const handleHeaderClick = () => {
        if (arraySelectedEmployee.length === arrayEmployee.length) {
            setArraySelectedEmployee([]);
        } else {
            setArraySelectedEmployee(arrayEmployee.map(emp => emp._id));
        }
    };

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayEmployee, event.target.value)
        setFilteredArrayData(filterArray)
    }

    function applyFilters(arrayDataAll, query) {
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['name'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (query === "") {
            return arrayDataAll;
        }
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }
    return (
        <Dialog
            open
            fullWidth
            onClose={() => props.onClose(arraySelectedEmployee)}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
                style: {
                    border: '1px #1F243B solid',
                    borderRadius: '15px',
                },
            }}
        >
            <Stack p={2} gap={2}>
                <Box border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'}>
                    <SearchInput handleSubmit={handleQueryChange} />
                </Box>
                <Box border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'} sx={{ height: "400px" }}>
                    <DefaultDataGrid
                        columns={columns(arraySelectedEmployee, arrayEmployee, onCheck, handleHeaderClick)}
                        rows={filteredArrayData}
                        onRowClick={({ id }) => {
                            console.log("id", id);
                        }}
                    />
                </Box>
                <Stack direction={'row'} >
                    <Box flexGrow={1} />
                    <DefaultButton color={'success'}
                        onClick={() => {
                            props.onClose(arraySelectedEmployee)
                        }}
                    >
                        Save
                    </DefaultButton>
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}